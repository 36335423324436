export type ChannelData = {
  githubDesktopReleasesRepo: string
}

// NOTE: Duplication — the channels source of truth is <https://github.com/httpie/pie-app/blob/qa/pie-core/channels.ts>
export enum Channel {
  STABLE = 'stable',
  QA = 'qa',
  SANDBOX = 'sandbox'
}

export const CHANNELS: Record<string, ChannelData> = {
  [Channel.STABLE]: {
    githubDesktopReleasesRepo: 'httpie/desktop',
  },
  [Channel.QA]: {
    githubDesktopReleasesRepo: 'httpie/desktop-qa',
  },
  [Channel.SANDBOX]: {
    githubDesktopReleasesRepo: 'httpie/desktop-sandbox',
  },
}

export const getCurrentChannel = () => {
  // following variables will contain channel name depending on the execution context
  // - NEXT_PUBLIC_CHANNEL for browser
  // - CHANNEL for nodejs when downloading GitHub releases
  const channel = (process.env.NEXT_PUBLIC_CHANNEL || process.env.CHANNEL || Channel.QA) as Channel
  return CHANNELS[channel]
}

import clsx from 'clsx'
import React from 'react'

import AppSection from './AppSection/AppSection'
import CLISection from './CLISection/CLISection'
import styles from './HomePromo.module.css'

interface Props {
  reverseSections?: boolean
}

const HomePromo: React.FC<Props> = ({ reverseSections = false }) => {
  return (
    <div className={clsx(styles.wrapper, reverseSections && styles.reverse)}>
      <AppSection reverse={reverseSections} />
      <CLISection reverse={reverseSections} />
    </div>
  )
}

export default HomePromo

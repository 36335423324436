import Image from 'next/legacy/image'
import clsx from 'clsx'

import { PAGE_ROUTES } from '~/constants'
import Text from '~/components/Text/Text'
import Button from '~/components/Button/Button'
import InstallationSelect from '~/components/InstallationSelect/InstallationSelect'
import TweetCardEmbed from '~/components/TestimonialCard/TweetCardEmbed'

import styles from './CLISection.module.css'

interface Props {
  reverse: boolean
}
const CLISection: React.FC<Props> = ({ reverse }) => (
  <div className={clsx(styles.wrapper, reverse && styles.reverse, 'site-container')}>
    <div className={styles.imageWrapper}>
      <div className={styles.image}>
        <div className={styles.blackTile}>
          <Image src='/Images/pie-black-tile.svg' layout='fill' />
        </div>
        <div className={clsx('hidden xl:block', styles.tweet)}>
          <TweetCardEmbed color='blue' slug='github-blog' />
        </div>
      </div>
    </div>

    <div className={clsx(styles.content, reverse && styles.reverse)}>
      <div>
        <Text variant='subheading' fontSize={[28, 28, 28, 40]} className={styles.heading}>
          HTTPie for Terminal
        </Text>
        <Text variant='body' fontSize={[18, 14, 14, 18]} className={styles.description}>
          The original. An open-source API testing client for open minds.
        </Text>
      </div>

      <InstallationSelect />

      <Button
        link={PAGE_ROUTES.CLI}
        className={styles.button}
        data-track='cta:learn-more-about-cli:from-homepage-promo'
      >
        Learn more
      </Button>
    </div>
  </div>
)

export default CLISection

import clsx from 'clsx'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import useClipboard, { COPY_STATE_TIMEOUT } from '~/lib/useClipboard'
import { makeTrackAttr } from '~/lib/ga-events'
import { overwriteTrackAttrsForCodeExampleCopyAction } from '~/components/InstallationSelect/InstallationSelect'
import Icon from '~/components/Icon/Icon'
import Tooltip from '~/components/Tooltip/Tooltip'

import styles from './CopyButton.module.sass'

export type CopyButtonVariant = 'regular' | 'borderless'

interface Props {
  variant?: CopyButtonVariant
  textToCopy: string
  language?: string
  show?: boolean
  className?: string
  trackAction?: string
  trackCategory?: string
}

export type CopyButtonRef = {
  triggerCopy: () => void
}

const TRACK_DEFAULT_CATEGORY = 'copy'
const TRACK_DEFAULT_ACTION = 'copy-code-example'
const TRACK_LABEL_LIMIT = 50

const getCopyTrackAttrs = (category: string, action: string, language = '', text: string) => {
  const overwrites = overwriteTrackAttrsForCodeExampleCopyAction(language, text)
  if (overwrites) {
    category = overwrites.category
    action = overwrites.action
  }
  const langLabel = language ? `[${language}] ` : ''
  const textLabel = text.length <= TRACK_LABEL_LIMIT ? text : `${makeTrackAttr(text.substr(0, TRACK_LABEL_LIMIT))}…`
  const label = langLabel + textLabel
  return makeTrackAttr(category, action, label)
}

const COPIED_STATE_TEXT = 'Copied!'
const COPY_STATE_TEXT = 'Copy'
// Keyframes are being used for animation, we don't want copy button to appear second time
const CHECKMARK_ANIMATION_DURATION = COPY_STATE_TIMEOUT - 150

const CopyButton = forwardRef<CopyButtonRef, Props>(
  (
    {
      variant = 'regular',
      textToCopy,
      language,
      className,
      show = true,
      trackCategory = TRACK_DEFAULT_CATEGORY,
      trackAction = TRACK_DEFAULT_ACTION,
    },
    ref
  ) => {
    const { hasCopied, onCopy } = useClipboard(textToCopy)
    const [visible, setVisible] = useState(hasCopied ?? show)

    useEffect(() => {
      let timeoutId: number | undefined

      // Prevent double clicking copy button to reset the timeout
      if (hasCopied && visible) {
        return
      }

      if (!timeoutId && !hasCopied) {
        return setVisible(false)
      }

      // Show the button even it shouldn't be visible based on prop 'show' state
      // Hide after animation is done (timeout)
      if (!show && hasCopied) {
        setVisible(true)
        timeoutId = window.setTimeout(() => {
          setVisible(false)
        }, CHECKMARK_ANIMATION_DURATION)
      }

      return () => {
        window.clearTimeout(timeoutId)
      }
    }, [show, hasCopied, visible])

    // useImperativeHandle is used to extends button ref by method that manually triggers onCopy method
    // You can trigger the method outside of the component if the textToCopy is known
    // <https://stackoverflow.com/questions/57005663/when-to-use-useimperativehandle-uselayouteffect-and-usedebugvalue>
    useImperativeHandle(ref, () => ({
      triggerCopy() {
        onCopy()
      },
    }))

    const isVisible = show || visible
    const trackAttrs = getCopyTrackAttrs(trackCategory, trackAction, language, textToCopy)

    return (
      <div className={clsx(styles.button, styles[variant], isVisible && styles.visible, hasCopied && styles.copied)}>
        <div
          className={clsx(styles.iconWrapper, className)}
          onClick={onCopy}
          role='button'
          tabIndex={0}
          data-track={trackAttrs}
        >
          {hasCopied ? (
            <Icon name='checkmark' className={clsx(styles.icon, styles.checkmarkIcon)} />
          ) : (
            <Icon name='copy' className={styles.icon} />
          )}
        </div>
        {isVisible && <Tooltip className={styles.tooltip} text={hasCopied ? COPIED_STATE_TEXT : COPY_STATE_TEXT} />}
      </div>
    )
  }
)

export default CopyButton

import dynamic from 'next/dynamic'
import type { GetStaticProps } from 'next'

import { Layout } from '~/components/Layout'
import HomeHero from '~/sections/HomeHero/HomeHero'
import HomePromo from '~/sections/HomePromo/HomePromo'
import TweetSection from '~/sections/Tweets/TweetSection'
import Companies from '~/sections/Companies/Companies'
import Community from '~/sections/Community/Community'
import { Post } from '~/content/blog/types'
import { getOverallStargazers } from '~/lib/useStargazers'
import { getLayoutProps } from '~/lib/layout'
import { PageProps } from '~/lib/types'
import { getPost } from '~/server'
import { GITHUB_ALL_PUBLIC_REPOS } from '~/data'
import { POST_SLUGS } from '~/content/blog/posts'

const HomeFeaturedPost = dynamic(() => import('~/sections/HomeFeaturedPost/HomeFeaturedPost'))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NEWEST_POST_SLUG = POST_SLUGS[0]

// Set to `null` to not show any feature post on the homepage.
const FEATURED_POST_SLUG = null //NEWEST_POST_SLUG

export const getStaticProps: GetStaticProps = async () => {
  const layoutProps = getLayoutProps()
  const featuredPost = FEATURED_POST_SLUG && (await getPost(FEATURED_POST_SLUG))

  return {
    props: {
      featuredPost,
      orgRepoStargazers: getOverallStargazers(GITHUB_ALL_PUBLIC_REPOS),
      layoutProps,
    },
  }
}

interface Props extends PageProps {
  featuredPost: Post | null
  orgRepoStargazers: number | null
}

const Homepage: React.FC<Props> = ({ orgRepoStargazers, layoutProps, featuredPost }) => {
  return (
    <Layout {...layoutProps}>
      <HomeHero />
      {featuredPost && <HomeFeaturedPost featuredPost={featuredPost} />}
      <HomePromo reverseSections={false} />
      <TweetSection />
      <Companies />
      <Community orgRepoStargazers={orgRepoStargazers} />
    </Layout>
  )
}

export default Homepage

import React from 'react'
import Image from 'next/legacy/image'
import clsx from 'clsx'

import { PAGE_ROUTES, PUBLIC_BETA_BADGE_LINK } from '~/constants'
import { useOSName } from '~/lib/os'
import { getDownloadButtonLabel } from '~/lib/download/download'
import Text from '~/components/Text/Text'
import Button from '~/components/Button/Button'
import Link from '~/components/Link/Link'
import EarlyAccessBadge from '~/components/EarlyAccessBadge/EarlyAccessBadge'
import { BadgeVariant } from '~/components/EarlyAccessBadge/types'

import styles from './AppSection.module.css'

interface Props {
  reverse: boolean
}

const AppSection: React.FC<Props> = ({ reverse }) => {
  const osName = useOSName()

  return (
    <div className={clsx(styles.wrapper, reverse && styles.reverse, 'site-container')}>
      <div className={clsx(styles.content, reverse && styles.reverse)}>
        <EarlyAccessBadge
          variant={BadgeVariant.LABEL}
          className={styles.earlyAccessBadge}
          pingAnimation
          data-track='cta:early-access-badge:from-homepage-promo'
          link={PUBLIC_BETA_BADGE_LINK}
        >
          Public beta
        </EarlyAccessBadge>

        <Text variant='subheading' fontSize={[28, 28, 28, 40]} className={styles.heading}>
          {'HTTPie for\nWeb & Desktop'}
        </Text>
        <Text variant='body' fontSize={[18, 14, 14, 18]} className={styles.description}>
          All the user-friendliness of our Terminal version, now wrapped up in a sleek graphical interface.
        </Text>

        <div className={styles.buttons}>
          <Button
            link={PAGE_ROUTES.DESKTOP}
            className={styles.button}
            data-track='cta:learn-more-about-app:from-homepage-promo'
          >
            Learn more
          </Button>
          <div className={styles.appButtons}>
            <Button
              variant='primary'
              link={PAGE_ROUTES.APP}
              className={styles.button}
              data-track='cta:go-to-app:from-homepage-promo'
            >
              Go to App →
            </Button>
            <Text fontSize={15} className={styles.appSectionLink}>
              <Link href={PAGE_ROUTES.DOWNLOAD} data-track='cta:get-the-app:from-homepage-promo' underlined>
                {getDownloadButtonLabel(osName)}
              </Link>
            </Text>
          </div>
        </div>
      </div>

      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          <div className={styles.greenTile}>
            <Image src='/Images/pie-green-tile.svg' layout='fill' />
          </div>
          {/*<div className={clsx('hidden xl:block', styles.tweet)}>
            <TweetCardEmbed color='pink' slug='holy-crap' />
          </div>*/}
        </div>
      </div>
    </div>
  )
}

export default AppSection

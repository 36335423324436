import React from 'react'
import dynamic from 'next/dynamic'

import { useContentLoaded } from '~/lib/content'
import { Breakpoint, useBreakpoint } from '~/lib/viewport'

import styles from './HomeHero.module.css'

const MOBILE_BREAKPOINTS: Breakpoint[] = ['sm']

const DesktopAnimation = dynamic(() => import('./Animations/Desktop'), { ssr: false })
const MobileAnimation = dynamic(() => import('./Animations/Mobile'), { ssr: false })

const HeroAnimation = () => {
  const contentLoaded = useContentLoaded()
  const currentBreakpoint = useBreakpoint()
  const isMobile = MOBILE_BREAKPOINTS.includes(currentBreakpoint)

  return (
    <div className={styles.animationWrapper}>
      {contentLoaded && (isMobile ? <MobileAnimation /> : <DesktopAnimation />)}
    </div>
  )
}

export default HeroAnimation

import { useCallback, useEffect, useState } from 'react'

interface CopyOptions {
  timeout?: number
}

export const COPY_STATE_TIMEOUT = 2000

const useClipboard = (value: string, { timeout = COPY_STATE_TIMEOUT }: CopyOptions = {}) => {
  const [hasCopied, setHasCopied] = useState(false)

  const onCopy = useCallback(() => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setHasCopied(true)
      })
      .catch(() => {
        setHasCopied(false)
      })
  }, [value])

  useEffect(() => {
    let timeoutId: number | null = null
    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false)
      }, timeout)
    }
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [timeout, hasCopied])

  return {
    value,
    hasCopied,
    onCopy,
  }
}

export default useClipboard

import clsx from 'clsx'

import Text from '~/components/Text/Text'

import styles from './Tooltip.module.sass'

interface Props {
  placement?: 'top' | 'bottom' | 'left' | 'right'
  target?: HTMLElement
  trigger?: 'hover' | 'click' | 'always'
  text: string
  className?: string
}

const Tooltip: React.FC<Props> = ({ text, className }) => {
  return (
    <div className={clsx(styles.tooltip, className)}>
      <Text fontSize={13} className={styles.text}>
        {text}
      </Text>
    </div>
  )
}

export default Tooltip

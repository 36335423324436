import { useState, ChangeEvent, useEffect, MouseEvent, useRef } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { CLI_INSTALL_URL } from '~/constants'
import { useOSName, OS } from '~/lib/os'
import Text from '~/components/Text/Text'
import Icon from '~/components/Icon/Icon'
import useOnClickOutside from '~/lib/useOnClickOutside'

import CopyButton from '../CopyButton/CopyButton'

import styles from './InstallationSelect.module.css'

const TOOL_OPTIONS = ['apt', 'brew', 'choco', 'pip', 'port', 'snap', 'yum', 'more…'] as const
// Create Typescript's type out of array of possible strings
type ToolName = typeof TOOL_OPTIONS[number]

const REDIRECT_OPTION: ToolName = 'more…'
const DEFAULT_OPTIONS: Record<OS, ToolName | null> = {
  Mac: 'brew',
  Windows: 'choco',
  Linux: 'snap',
  iOS: null,
  Other: null,
}

const TRACK_CATEGORY = 'install-cli'
const TRACK_ACTION_EXPAND = 'expand-install-options'
const TRACK_ACTION_SELECT_OPTION = 'select-install-option'
const TRACK_ACTION_INSTALL_COPY = 'copy-install-command'
const TRACK_ACTION_UPGRADE_COPY = 'copy-upgrade-command'

export const overwriteTrackAttrsForCodeExampleCopyAction = (language: string, text: string) => {
  // Overwrite track attributes for code example copy action if it looks like an install/upgrade command.
  if (language == 'bash') {
    text = text.toLowerCase()
    // Heuristics
    if (text.includes('httpie')) {
      const hasInstall = text.includes('install')
      const hasUpgrade = text.includes('upgrade')
      // This specific check relies on the `# Upgrade httpie` comment in the code example.
      // If that is removed, this will need to be updated.
      // But things won’t break in the meantime—we’ll just track as install instead.
      const hasUpgradeHTTPie = text.includes('upgrade httpie')
      if (hasInstall || hasUpgrade) {
        return {
          category: TRACK_CATEGORY,
          action: hasUpgradeHTTPie ? TRACK_ACTION_UPGRADE_COPY : TRACK_ACTION_INSTALL_COPY,
        }
      }
    }
  }
}

const renderToolName = (selectedOption: ToolName | null, onClick: (event: MouseEvent<HTMLSpanElement>) => void) => (
  <span
    className={styles.tool}
    onClick={onClick}
    role='button'
    tabIndex={0}
    data-track={`${TRACK_CATEGORY}:${TRACK_ACTION_EXPAND}`}
  >
    {selectedOption ? (
      <strong>{selectedOption}</strong>
    ) : (
      <>
        {'<'}
        <strong>tool</strong>
        {'>'}
      </>
    )}
    <Icon name='dropdown' className={styles.icon} />
  </span>
)

interface Props {
  className?: string
}

const InstallationSelect: React.FC<Props> = ({ className }) => {
  const router = useRouter()
  const osName = useOSName()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<ToolName | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const toggle = () => {
    setIsOpen((prevState) => !prevState)
  }

  const handleNativeOptionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value as ToolName)
  }

  const handleCustomOptionChange = (option: ToolName) => () => {
    if (option === REDIRECT_OPTION) {
      router.push(CLI_INSTALL_URL, undefined, { shallow: true, scroll: false })

      // Don't put redirect option inside the dropdown as a tool name
      return
    }

    setSelectedOption(option)
    setIsOpen(false)
  }

  useEffect(() => {
    setSelectedOption(DEFAULT_OPTIONS[osName])
  }, [osName])

  // Close dropdown on click outside the wrapper
  useOnClickOutside(wrapperRef, () => setIsOpen(false))

  return (
    <div className={clsx(styles.wrapper, className)} ref={wrapperRef}>
      <div className={clsx(styles.selectBox, isOpen && styles.isOpen)}>
        <Text variant='mono' fontSize={15} className={styles.selectBoxText}>
          <span className='token prompt'>$</span>
          {renderToolName(selectedOption, toggle)}install httpie
        </Text>
        {selectedOption && (
          <div className={styles.copyWrapper}>
            <CopyButton
              variant='borderless'
              textToCopy={`${selectedOption} install httpie`}
              className={styles.iconWrapper}
              trackCategory={TRACK_CATEGORY}
              trackAction={TRACK_ACTION_INSTALL_COPY}
            />
          </div>
        )}
      </div>

      <select className={styles.selectNative} onChange={handleNativeOptionChange}>
        {TOOL_OPTIONS.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <div className={clsx(styles.selectCustom, isOpen && styles.isOpen)} aria-hidden='true'>
        {TOOL_OPTIONS.map((option) => (
          <div
            key={option}
            className={clsx(styles.option, option === selectedOption && styles.selected)}
            onClick={handleCustomOptionChange(option)}
            role='button'
            tabIndex={0}
            data-track={`${TRACK_CATEGORY}:${TRACK_ACTION_SELECT_OPTION}:${option}`}
          >
            <Text variant='mono' fontSize={15} className={styles.optionText}>
              {option}
            </Text>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InstallationSelect

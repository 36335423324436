import { useSpring, animated } from 'react-spring'
import clsx from 'clsx'

import Text from '~/components/Text/Text'
import { useContentLoaded } from '~/lib/content'

import HeroAnimation from './HeroAnimation'
import styles from './HomeHero.module.css'

const HomeHero: React.FC = () => {
  const contentLoaded = useContentLoaded()
  const fadeInStyles = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(5%)' },
    delay: 1000,
    pause: !contentLoaded,
  })

  return (
    <div className={clsx(styles.wrapper, 'site-container')}>
      <HeroAnimation />

      <animated.div className={clsx(styles.textSection)} style={fadeInStyles}>
        <Text variant='headline' fontSize={[55, 64, 109]} className={styles.headline}>
          {'FLOW\nTHROUGH\nAPIs'}
        </Text>
        <Text variant='body' fontSize={[18, 14, 18]} className={styles.body}>
          {'HTTPie is making APIs simple\nand intuitive for those building\nthe tools of our time.'}
        </Text>
      </animated.div>
    </div>
  )
}

export default HomeHero

import { GITHUB_DESKTOP_LATEST_RELEASE } from '~/data'
import { OS } from '~/lib/os'
import { IconName } from '~/components/Icon/names'
import { getCurrentChannel } from '~/lib/channels'

import { OSConfig, DesktopRelease, DownloadUrl, PlatformRelease, DownloadButton, DesktopAppOS } from './types'

const channel = getCurrentChannel()

const APP_DOWNLOAD_LABEL_TEMPLATE = 'Get %s App'
const APP_DOWNLOAD_CONFIG: Record<DesktopAppOS, OSConfig | null> = {
  [OS.Mac]: {
    icon: 'apple',
    packages: [
      {
        extension: '.dmg',
        name: '(Intel)',
      },
      {
        extension: '-arm64.dmg',
        name: '(Apple Silicon)',
      },
    ],
  },
  [OS.Windows]: {
    icon: 'microsoft',
    packages: [{ extension: '.exe' }],
  },
  [OS.Linux]: {
    icon: 'linux',
    packages: [{ extension: '.AppImage' }],
    downloadButtonOverwrites: {
      link: `https://github.com/${channel.githubDesktopReleasesRepo}#linux`,
      isDownload: false,
    },
  },
}

export const getLatestDesktopRelease = (): DesktopRelease => {
  const rawRelease = GITHUB_DESKTOP_LATEST_RELEASE

  const getDownloadUrl = (extension: string): DownloadUrl => {
    const re = new RegExp('\\d+\\.\\d+\\.\\d+%s$'.replace('%s', extension.replace('.', '\\.')))

    return (
      rawRelease.assets.find(({ browser_download_url }) => re.test(browser_download_url))?.browser_download_url ?? ''
    )
  }

  const platforms: PlatformRelease[] = []
  Object.entries(APP_DOWNLOAD_CONFIG).map(([osName, config]) => {
    if (!config) {
      return
    }

    config.packages.forEach((pkg) => {
      platforms.push({
        os: osName as DesktopAppOS,
        name: pkg.name ? `${osName} ${pkg.name}` : osName,
        downloadUrl: getDownloadUrl(pkg.extension),
      })
    })
  })

  return {
    name: rawRelease.name || rawRelease.tag_name,
    published: rawRelease.published_at,
    platforms,
  }
}

export const getDesktopDownloadButtons = (release: DesktopRelease): DownloadButton[] => {
  const buttons: DownloadButton[] = []

  release.platforms.forEach(({ os, name, downloadUrl }) => {
    const config = APP_DOWNLOAD_CONFIG[os]
    if (!config) {
      return
    }
    buttons.push({
      icon: config.icon,
      text: `Download for ${name}`,
      link: downloadUrl,
      isDownload: true,
      ...config.downloadButtonOverwrites,
    })
  })

  return buttons
}

export const getDownloadButtonLabel = (os: OS) => {
  return APP_DOWNLOAD_LABEL_TEMPLATE.replace('%s', APP_DOWNLOAD_CONFIG[os as DesktopAppOS] ? `the ${os}` : 'Desktop')
}

export const getOSIcon = (os: OS): IconName | undefined => {
  return APP_DOWNLOAD_CONFIG[os as DesktopAppOS]?.icon
}

import {getTweetBySlug} from '~/lib/testimonials/tweets'
import {TestimonialVariant} from '~/lib/testimonials/types'

import TestimonialCard from './TestimonialCard'
import {TestimonialCardColor} from './types'

interface Props {
  color: TestimonialCardColor
  slug: string
  hoverable?: boolean
}

const TweetCardEmbed: React.FC<Props> = ({ color, slug, hoverable = true }) => (
  <TestimonialCard
    variant={TestimonialVariant.TWEET}
    hoverable={hoverable}
    color={color}
    testimonial={getTweetBySlug(slug)}
  />
)

export default TweetCardEmbed

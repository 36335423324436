/**
 * Posts with corresponding slugs will be rendered on main blog page in a following order.
 */
export const POST_SLUGS = [
  'changelog-0025',
  'changelog-0024',
  'changelog-0023',
  'changelog-0022',
  'ai',
  'changelog-0021',
  'changelog-0020',
  'changelog-0019',
  'changelog-0018',
  'changelog-0017',
  'changelog-0016',
  'httpie-3.2.0',
  'changelog-0015',
  'changelog-0014',
  'stardust',
  'httpie-3.1.0',
  'changelog-0013',
  'changelog-0012',
  'changelog-0011',
  'changelog-0010',
  'httpie-3.0.0',
  'changelog-0009',
  'changelog-0008',
  'changelog-0007',
  'changelog-0006',
  'changelog-0005',
  'changelog-0004',
  'changelog-0003',
  'changelog-0002',
  'changelog-0001',
  'httpie-2.6.0',
  'httpie-2.5.0',
  'cli-api-diving',
  'cli-api-tricks',
]
